<template>
  <div class="container pt-3">
    <div v-if="haveCashback" class="claim">
      <div class="float-left">
        <span class="responsiveElipsis">{{ cashbackAmount }} {{ currency }}</span>
      </div>
      <div class="float-right">
        <button @click="this.claimCashback()" v-bind:class="cashbackClaiming == true ? 'txtdisabled' : ''"
          title="Claim Cashback">
          CLAIM
        </button>
        <button title="About Cashback" data-toggle="modal" data-target="#exampleModalLive"
          style="margin-left: 5px; padding: 5px 15px" @click="showPopup">
          <img src="/images/info.svg" style="width: 20px; height: 20px" />
        </button>
      </div>
      <div class="clearfix"></div>
    </div>
    <div v-if="haveCashback" class="text-start text-hint ps-1 pt-1 mb-3">
      Instant Cashback
    </div>
    <div class="row" style="
        font-size: 75%;
        max-width: 450px;
        margin: 0 auto;
        text-align: center;
      ">
      <div class="col-6" style="padding-left: 10px">
        <div :class="this.state.winCombination == 9 ? 'winCombination' : ''">
          <span>ROYAL FLUSH ------ x250</span>
        </div>
        <div :class="this.state.winCombination == 8 ? 'winCombination' : ''">
          <span>STRAIGHT FLUSH --- x50</span>
        </div>
        <div :class="this.state.winCombination == 7 ? 'winCombination' : ''">
          <span>FOUR OF A KIND ---- x25</span>
        </div>
        <div :class="this.state.winCombination == 6 ? 'winCombination' : ''">
          <span>FULL HOUSE ----------- x9</span>
        </div>
        <div :class="this.state.winCombination == 5 ? 'winCombination' : ''">
          <span>FLUSH ------------------- x6</span>
        </div>
      </div>

      <div class="col-6">
        <div :class="this.state.winCombination == 4 ? 'winCombination' : ''">
          <span>STRAIGHT -------------- x4</span>
        </div>
        <div :class="this.state.winCombination == 3 ? 'winCombination' : ''">
          <span>THREE OF A KIND ---- x3</span>
        </div>
        <div :class="this.state.winCombination == 2 ? 'winCombination' : ''">
          <span>TWO PAIRS ------------ x2</span>
        </div>
        <div :class="this.state.winCombination == 1 ? 'winCombination' : ''">
          <span>JACKS OR BETTER ---- x1</span>
        </div>
      </div>
    </div>
    <div class="wrapCards">
      <Card :cardsHandler="cardsHandler" :cardindex="0" />
      <Card :cardsHandler="cardsHandler" :cardindex="1" />
      <Card :cardsHandler="cardsHandler" :cardindex="2" />
      <Card :cardsHandler="cardsHandler" :cardindex="3" />
      <Card :cardsHandler="cardsHandler" :cardindex="4" />
      <div class="clearfix"></div>
    </div>

    <div class="kenoconnect" v-if="state.buttonstate == 'unlock'">
      <button class="betbutton" @click="videopokerGame.unlock()">UNLOCK</button>
    </div>
    <div class="clearfix"></div>
    <div class="kenoconnect" v-if="state.buttonstate == 'connect'">
      <button class="betbutton" @click="videopokerGame.connect()">
        CONNECT
      </button>
    </div>
    <div class="clearfix"></div>

    <div class="gameBet" v-if="state.buttonstate == 'play'">
      <div class="text-start text-hint ps-1">
        Min Bet {{ videopokerBetlimits.minBet }} / Max Bet
        {{ videopokerBetlimits.maxBet }} {{ currency }}
      </div>
      <input class="betinput" type="number" v-model="betAmount" />
      <button @click="betAmount = gameHelper.modifyBet(betAmount, 1)" class="btn betModifiers">
        x2
      </button>
      <button @click="betAmount = gameHelper.modifyBet(betAmount, 2)" class="btn betModifiers">
        x/2
      </button>
      <div></div>
      <div class="text-start text-hint ps-1 pt-1" v-if="betAmount > 0 && haveCashback">
        Cashback amount {{ calculateCashback(betAmount) }}
        {{ currency }}
      </div>
      <div>
        <button class="betbutton" @click="makeBet" ref="makeBet">
          <span v-if="state.videopokerbetloader" class="spinner-border text-warning me-2" role="status"
            aria-hidden="true"></span>
          <span>{{
              this.state.firstBet
                ? "Deal"
                : this.state.playAgain
                  ? "Play Again"
                  : "Play"
          }}</span>
        </button>
      </div>
      <div class="clearleft"></div>
    </div>

    <div class="clearfix"></div>
    <div class="videopokerbet pt-1">
      <div class="betstabholder">
        <div class="betstab" :class="
          historyManager.currentBetHistoryList == 'MY' ? 'betstabactive' : ''
        " @click="historyManager.setBetHistoryList('MY')">
          MY
        </div>
        <div class="betstab" :class="
          historyManager.currentBetHistoryList == 'ALL' ? 'betstabactive' : ''
        " @click="historyManager.setBetHistoryList('ALL')">
          ALL
        </div>
      </div>
    </div>

    <div class="clearfix"></div>
    <div class="betstabbottomline"></div>

    <TokenHistoryTable :data="historyManager.betHistory" />


    <Popup v-show="wrongNetwork.state" @close="closePopup" :title="wrongNetwork.title" :body="wrongNetwork.body"
      fontSize="normal" />
    <Popup v-show="isPopupVisible" @close="closePopup" title="INSTANT CASHBACK RULES" :body="getCashbackText()" />
    <PromoPopup
      :state="dailyCashbackPopup.state"
      :title="dailyCashbackPopup.title"
      :body="dailyCashbackPopup.body"
      :closeButton="true"
      fontSize="normal"
    />

  </div>
</template>

<script>
import VideopokerGame from "../index.js";
import gameHelper from "../../helpers/gameHelper.js";
import TokenHistoryTable from "../../components/TokenHistoryTable";
import Card from "./Card.vue";

import HistoryManager from "../../../services/historymanager";
import Videopoker from "../contracts/Videopoker.min.json";

import CashbackHelper from "../../../services/cashbackhelper.js";
import Cashback from "../../../services/Cashback.min.json";
import Popup from "@/components/Popup.vue";

import Promo2 from "../../../services/Promo2.min.json";
import Promo2Helper from "../../../services/promo2helper";
import PromoPopup from "@/components/PromoPopup.vue";

import Token from "../../../services/Token.min.json";
import TokenHelper from "../../../services/tokenhelper.js";


export default {
  name: "GamePlay",
  components: {
    TokenHistoryTable,
    Card,
    Popup,
    PromoPopup
  },
  data() {
    const state = {
      videopokermessage: true,
      videopokerwin: false,
      videopokerWinAmount: "0.0",
      videopokerbet: false,
      videopokerbetloader: false,
      videopokerGameRestored: false,
      videopokerMessageStr: "Connecting...",
      videopokercurrentBet: 0.001,
      firstBet: false,
      playAgain: false,
      winCombination: 0,
      buttonstate: "connect",
      game: "videopoker",
      cards: [
        { card: "cardspng/B.png", selected: false },
        { card: "cardspng/B.png", selected: false },
        { card: "cardspng/B.png", selected: false },
        { card: "cardspng/B.png", selected: false },
        { card: "cardspng/B.png", selected: false },
      ],
    };

    var videopokerGame = null;
    var historyManager = null;
    var cashbackHelper = null;
    var promo2Helper = null
    var tokenHelper = null
    return {
      videopokerGame,
      historyManager,
      cashbackHelper,
      promo2Helper,
      tokenHelper,
      state,
      haveCashback: true,
      cashbackAmount: 0,
      isPopupVisible: false,
      cashbackClaiming: true,
      cardsView: [null, null, null, null, null],
      gameHelper: new gameHelper(state.game),
      currency: this.$store.getters.getCurrency,
      betAmount: this.$store.getters.getGameLimits[state.game].min,
      videopokerBetlimits: {
        minBet: this.$store.getters.getGameLimits[state.game].min,
        maxBet: this.$store.getters.getGameLimits[state.game].max,
      },
      wrongNetwork: {
        state: false,
        title: 'Wrong Network',
        body: `You are trying to play with {{url network}} but you are connected to {{user network}}. 
                Please connect to {{url networ}} and click reload button below
                <div class="text-center"><a class="btn btn-secondary btn-sm mt-3">Reload</a></div>`,
      },
      dailyCashbackPopup: {
        state: false,
        title: "We've got you covered!",
        body: `<center><div style="text-align: left;font-size:19px;font-weight:600;">As it was your first bet today in this game, we have REFUNDED 1 TT back to your Cashback balance, to retrieve it just press the cashback "Claim" button!</div></center>`,
      }
    };
  },
  unmounted() {
    this.videopokerGame.destroy();
    this.historyManager.destroy()
    this.promo2Helper.destroy()
    this.cashbackHelper.destroy();
    this.tokenHelper.destroy()
  },
  beforeMount() {
    this.historyManager = new HistoryManager(this.historyHandler, this.state.game, Videopoker);
    this.promo2Helper = new Promo2Helper(this.messageHandler, Promo2);

    this.videopokerGame = new VideopokerGame(
      this.messageHandler,
      Videopoker,
      "videopoker"
    );
    this.videopokerGame.historyManager = this.historyManager;
    this.videopokerGame.init();

    this.$store.getters.getConfig.games["videopoker"].contract =
      this.videopokerGame.address;

    this.cashbackHelper = new CashbackHelper(this.messageHandler, Cashback);
    this.tokenHelper = new TokenHelper(this.messageHandler, Token);
    this.haveCashback = this.$store.state.user.currency.cashback;
  },
  mounted() {
    this.$store.commit("setEnableFast", false);

    //preload cards
    var allCards = [
      "2C.png",
      "2H.png",
      "3C.png",
      "3H.png",
      "4C.png",
      "4H.png",
      "5C.png",
      "5H.png",
      "6C.png",
      "6H.png",
      "7C.png",
      "7H.png",
      "8C.png",
      "8H.png",
      "9C.png",
      "9H.png",
      "AC.png",
      "AH.png",
      "B.png",
      "JD.png",
      "JS.png",
      "KD.png",
      "KS.png",
      "QD.png",
      "QS.png",
      "TD.png",
      "TS.png",
      "2D.png",
      "2S.png",
      "3D.png",
      "3S.png",
      "4D.png",
      "4S.png",
      "5D.png",
      "5S.png",
      "6D.png",
      "6S.png",
      "7D.png",
      "7S.png",
      "8D.png",
      "8S.png",
      "9D.png",
      "9S.png",
      "AD.png",
      "AS.png",
      "JC.png",
      "JH.png",
      "KC.png",
      "KH.png",
      "QC.png",
      "QH.png",
      "TC.png",
      "TH.png",
    ];

    for (let index = 0; index < allCards.length; index++) {
      var img = new Image();
      img.src = "cardspng/" + allCards[index];
    }
    this.matchSizes();
    this.$nextTick(function () {
      window.addEventListener("resize", this.matchSizes);
    });
  },
  watch: {
    windowSize: function () {
      this.matchSizes();
    },
    "state.videopokerMessageStr": function (gameMessage) {
      if (gameMessage != "") {
        this.$toast.show(gameMessage);
      }
      this.state.videopokerMessageStr = "";
    },
    "$store.state.user.currency": function () {
      this.setCurrency(this.$store.state.user.currency);
    },
  },
  methods: {
    historyHandler(obj, list) {
      console.log(obj)
      if (this.videopokerGame.playeraddress != obj.address) {
        this.historyManager.addBetHistory(obj, list)
      }

    },
    setCurrency(_currency) {
      this.currency = _currency.name.toUpperCase();
      this.haveCashback = _currency.cashback;
      this.state.buttonstate = "play";
      this.videopokerGame.setCurrency(_currency);

      
      this.minBet = this.$store.getters.getGameLimits[this.state.game].min;
      this.maxBet = this.$store.getters.getGameLimits[this.state.game].max;
    },

    cardsHandler(obj) {
      this.cardsView[obj.cardindex] = obj;
    },
    setFirstBet(fb) {
      this.state.firstBet = fb;
      this.cardsView[0].setFirstBet(fb);
      this.cardsView[1].setFirstBet(fb);
      this.cardsView[2].setFirstBet(fb);
      this.cardsView[3].setFirstBet(fb);
      this.cardsView[4].setFirstBet(fb);
    },
    setCards(cards, flip) {
      this.cardsView[0].setCard(cards[0], flip);
      this.cardsView[1].setCard(cards[1], flip);
      this.cardsView[2].setCard(cards[2], flip);
      this.cardsView[3].setCard(cards[3], flip);
      this.cardsView[4].setCard(cards[4], flip);
    },
    setSelected(sel) {
      this.cardsView[0].setSelected(sel);
      this.cardsView[1].setSelected(sel);
      this.cardsView[2].setSelected(sel);
      this.cardsView[3].setSelected(sel);
      this.cardsView[4].setSelected(sel);
    },
    lockSelected() {
      this.cardsView[0].lockSelection();
      this.cardsView[1].lockSelection();
      this.cardsView[2].lockSelection();
      this.cardsView[3].lockSelection();
      this.cardsView[4].lockSelection();
    },
    getSelectedState() {
      var selectedIndexes = [];
      if (!this.cardsView[0].getSelectedState()) {
        selectedIndexes.push(0);
      }

      if (!this.cardsView[1].getSelectedState()) {
        selectedIndexes.push(1);
      }
      if (!this.cardsView[2].getSelectedState()) {
        selectedIndexes.push(2);
      }
      if (!this.cardsView[3].getSelectedState()) {
        selectedIndexes.push(3);
      }
      if (!this.cardsView[4].getSelectedState()) {
        selectedIndexes.push(4);
      }

      return selectedIndexes;
    },
    makeBet() {
      //reset
      if (this.state.videopokerbetloader) {
        return;
      }
      if (!this.state.firstBet) {
        this.setCards(
          [
            { card: "cardspng/B.png", selected: false },
            { card: "cardspng/B.png", selected: false },
            { card: "cardspng/B.png", selected: false },
            { card: "cardspng/B.png", selected: false },
            { card: "cardspng/B.png", selected: false },
          ],
          false
        );
        this.setSelected(false);
        this.state.winCombination = 0;
        if (!this.gameHelper.verify("betAmount", this.betAmount)) {
          return this.$toast.error(
            this.gameHelper.getMessage("invalid_bet_amount")
          );
        }
        this.videopokerGame.betHandler(this.betAmount);
      } else {
        this.lockSelected();
        this.videopokerGame.resolveHandler(this.getSelectedState());
      }
    },
    matchSizes() { },
    messageHandler(obj) {
      if (obj.type == "limits") {
        window.Logger.log(obj)
        this.betAmount = obj.min;
        this.minBet = obj.min;
        this.maxBet = obj.max;
        this.$store.getters.getGameLimits[this.state.game].min = this.minBet;
        this.$store.getters.getGameLimits[this.state.game].max = this.maxBet;
        this.state.gameBet = true;
        return;
      }
      //
      if (obj.type == "tokennotalowed") {
        this.state.buttonstate = "unlock";
        return;
      }
      if (obj.type == "tokenalowed") {
        this.state.buttonstate = "play";
        return;
      }
      //type=balance modis roca balasi ganaxlda
      if (obj.type == "balance") {
        if (obj.currency != this.currency) {
          return
        }
        let balance = parseFloat(obj.balance);
        if(obj.currency === 'TT'){
          this.$store.commit("setBalance", balance.toFixed(2));
        }else{
          this.$store.commit("setBalance", balance.toFixed(5));
        }
        this.$store.commit("setCurrency", obj.currency);
        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }
      if (obj.type == "cashbackadd") {
        this.cashbackAmount = obj.balance;
        if (parseFloat(this.cashbackAmount) > 0) {
          this.cashbackClaiming = false;
        }

        return;
      }
      if (obj.type == "tokenbalance") {
        console.log(obj)
        this.$store.commit("setTokenBalance", parseFloat(obj.balance).toFixed(3));
        return;
      }
      if (obj.type == "cashbackclaim") {
        this.$toast.success("Claimed " + obj.amount + " " + this.currency, {
          duration: 3000,
        });
        this.diceGame.fetchAccount();
        this.cashbackHelper.balanceOf();
        return;
      }
      //type=bethistory modis roca beti dafiksirda obj.address obj.bet obj.win
      if (obj.type == "bethistory") {
        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }

      if (obj.type == "networkchange") {
        console.log(obj);
        var url = this.gameHelper.getRedirectUrl(this.state.game, obj.networkVersion)
        console.log(url)
        if (url != null) {
          window.location.href = url
        } else {
          var chains = this.gameHelper.getChainsList(this.state.game)
          window.Logger.log(chains)
          this.wrongNetwork.state = true
          this.wrongNetwork.body = "This game is not available on this Chain/Token<br/><br/>"
          this.wrongNetwork.body += "You can play this game on the next Cnain(Tokens)<br/><br/>"
          for (let index = 0; index < chains.length; index++) {
            const element = chains[index];
            this.wrongNetwork.body += `${element.blockchain_name}(${element.currency_codes.join(', ')})<br/><br/>`
          }
          this.wrongNetwork.body += "Please change to correct Chain/Token<br/>"
          this.wrongNetwork.body += `and click reload button below
                <div class="text-center"><a href="${window.location.href}" class="btn btn-secondary btn-sm mt-3">Reload</a></div>`


        }
        // 
        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }


      if (obj.type == "accountset") {
        if (this.$store.getters.getUserWallet != obj.msg) {
          this.$store.commit("setUserWallet", obj.msg);
        }
        this.videopokerGame.playeraddress = obj.msg;
        if (!this.state.videopokerGameRestored) {
          setTimeout(
            function () {
              this.state.videopokerGameRestored = true;
              this.videopokerGame.getActiveGame();
            }.bind(this),
            100
          );
        }

        if (this.cashbackHelper.account == null) {
          this.cashbackHelper.onConnet(obj.msg);
          this.cashbackHelper.balanceOf();
        }
        if (this.tokenHelper.account == null) {
          this.tokenHelper.onConnet(obj.msg);
          this.tokenHelper.balanceOf();
        }
        if (
          this.$store.state.user.currency.address !=
          this.videopokerGame.tokenObj.address
        ) {
          this.setCurrency(this.$store.state.user.currency);
        }
        if (this.promo2Helper.account == null) {
          console.log(obj.msg)
          this.promo2Helper.onConnet(obj.msg);
        }
        return;
      }
      if (obj.type == "dailycashback") {
        console.log(obj)
        this.dailyCashbackPopup.state = true;
      }
      //type=error modis roca shecdoma dafiksirda
      if (obj.type == "error") {
        this.state.videopokerMessageStr = obj.msg;
        this.state.videopokermessage = true;
        setTimeout(
          function () {
            this.state.videopokermessage = false;
          }.bind(this),
          3000
        );
      } else {
        this.state.videopokerwin = false;
        this.state.videopokerbet = false;
        this.state.videopokerbetloader = false;
        console.log(obj)
        if (obj.type == "restoregame") {
          if (obj.cards.length > 0) {
            this.setFirstBet(true);
            this.setCards(obj.cards, false);
          }
        }else{
          this.state.buttonstate = "play";
        }
        
        //

        if (obj.type == "connected") {
          this.$store.commit("setCurrencyEnabled", this.$store.getters.getConfig.games[this.state.game].tokens);
          this.state.videopokermessage = false;
          this.state.videopokerbet = true;
        }



        

        //type=rejected modis roca userma metamaskze rejecti misca konektze da vachvenebt konektis gilaks
        //type=notconnected  modis roca useri araa dakonektebuli da vachvenebt konektis gilaks
        //da vmalavt mesijis divs
        if (obj.type == "rejected" || obj.type == "notconnected") {
          this.state.videopokermessage = false;
          this.state.buttonstate = "connect";
        }

        //type=betloader modis roca beti gavida blockchainze da velodebit dadasturebas, vachvenenbt preloaders
        if (obj.type == "betloader") {
          this.state.videopokerbetloader = true;
        }
        if (obj.type == "betresult") {
          this.setFirstBet(true);
          this.setCards(obj.cards, false);
          this.videopokerGame.fetchAccount();
        }

        if (obj.type == "betresolve") {
          this.tokenHelper.balanceOf();
          this.videopokerGame.fetchAccount();
          this.state.playAgain = true;
          this.setFirstBet(false);
          this.setCards(obj.cards, true);

          if (obj.win > 0) {
            setTimeout(
              function () {
                this.state.winCombination = obj.outcome;
                this.state.videopokerwin = true;
                this.state.videopokerWinAmount = obj.win;

                //or wamshi vmalavt winis mesijs da vachvenebt betis knopkas
                this.$toast.success(
                  this.gameHelper.getMessage("you_won_amount", obj.win),
                  { duration: 3000 }
                );
                this.$soundManager.play({ name: "win" });
                
                this.historyManager.addAllBetHistory({
                  address: obj.player,
                  bet: obj.bet,
                  tx_id: obj.tx_id,
                  win: obj.win,
                  currency: obj.currency,
                });

                this.state.videopokerwin = false;
                this.state.videopokerbet = true;
              }.bind(this),
              parseInt(1000 + 5 * 150)
            );
          } else {
            this.state.winCombination = obj.outcome;
            setTimeout(
              function () {
                this.historyManager.addAllBetHistory({
                  address: obj.player,
                  bet: obj.bet,
                  tx_id: obj.tx_id,
                  win: obj.win,
                  currency: obj.currency,
                });
              }.bind(this),
              parseInt(1000 + 5 * 150)
            );
            this.state.videopokerbet = true;
          }
        }

        if (obj.type == "betbutton") {
          this.state.videopokerbet = true;
        }
      }
    },
    claimCashback() {
      this.cashbackClaiming = true;
      this.cashbackHelper.claim();
    },
    calculateCashback(betAmount) {
      if (betAmount < 3000) {
        return (betAmount * 50) / 10000;
      } else if (betAmount < 5000) {
        return (betAmount * 60) / 10000;
      } else if (betAmount < 10000) {
        return (betAmount * 70) / 10000;
      } else {
        return (betAmount * 80) / 10000;
      }
    },
    showPopup() {
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false;
      this.wrongNetwork.state = false
    },
    getCashbackText() {
      return `<table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Bet amount</th>
                    <th scope="col">Cashback</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>= 10 000</td>
                    <td>0.8%</td>
                  </tr>
                  <tr>
                    <td>< 10 000</td>
                    <td>0.7%</td>
                  </tr>
                  <tr>
                    <td>< 5 000</td>
                    <td>0.6%</td>
                  </tr>
                  <tr>
                    <td>< 3 000</td>
                    <td>0.5%</td>
                  </tr>
                </tbody>
              <table>

              Example table:<br>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Bet amount</th>
                    <th scope="col">Cashback</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>10 000</td>
                    <td>80</td>
                  </tr>
                  <tr>
                    <td>5 000</td>
                    <td>35</td>
                  </tr>
                  <tr>
                    <td>3 000</td>
                    <td>18</td>
                  </tr>
                  <tr>
                    <td>1 000</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td>200</td>
                    <td>1</td>
                  </tr>
                </tbody>
              </table>

              * Cashback is rewarded only on lost bets<br>
              * Cashback is rewarded for any bet size<br>
              * Cashback has no wagering terms, can be claimed instantly<br>`;
    },
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #135067-->
<style scoped>
.holdHeld div {
  display: inline-block;
  width: 7vw;
  min-width: 70px;
  font-size: 12px;
  position: relative;
  opacity: 0;
}

.holdHeld div.active {
  opacity: 100;
}

.wrapCards {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  padding-bottom: 10px;
}

.winCombination {
  background: #ffe81c;
}

.cardbg {
  float: left;
  background: white;
  padding: 5px;
  border-radius: 10px;
  margin: 3px;
}

.cardbgselected {
  background: #ffe81c;
  float: left;
  padding: 5px;
  border-radius: 10px;
  margin: 3px;
}

.cardImg {
  min-width: 54px;
  width: 6vw;
}

.playcard {
  mix-blend-mode: darken;
}

.gameBet {
  max-width: 300px;
  margin: 0 auto;
}

.betinput {
  background: #d8d8d8;
  border-radius: 8px;
  font-size: 25px;
  border: none;
  text-align: center;
  padding: 5px;
  padding-top: 0px;
  max-width: 300px;
  width: 59%;
}

.betbutton {
  width: 60%;
  background: #262626;
  border-radius: 8px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  padding: 5px;
  margin-top: 9px;
  margin-bottom: 15px;
  height: 50px;
}

.betstabbottomline {
  height: 2px;
  background: #cfcfcf;
  width: 90%;
  margin: 0 auto;
}

.betModifiers {
  width: 18%;
  background: #262626;
  border-radius: 8px;
  color: #ffffff;
  text-align: center;
  padding: 7px 0px;
  top: -2px;
  position: relative;
  margin-left: 5px;
}

.bet {
  width: 5em;
}

.betstab {
  width: 30%;
  float: left;
  margin-left: 2px;
  color: var(--tabtxt-color);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: linear-gradient(0deg,
      var(--tabgs-color) 0%,
      var(--tabge-color) 100%);
  transition: 0.3s;
}

.betstabactive {
  color: var(--tabtxtselected-color);
  background: linear-gradient(0deg,
      var(--tabys-color) 0%,
      var(--tabye-color) 100%);
  transition: 0.3s;
}

.betstabHolder {
  justify-content: center;
  align-items: center;
}


.claim {
  width: 100%;
  background: #fff;
  padding: 10px 12px;
}

.claim span {
  margin-top: 4px;
  display: inline-block;
}

.claim button {
  border: none;
  outline: none;
  padding: 5px 30px;
  border-radius: 5px;
  color: #333333;
}

.claim img {
  position: relative;
  top: -1px;
}
</style>
