
import Game from "../../services/game"
import Web3 from "web3";

export default class VideopokerGame extends Game {
    allCards = [
        'AC', '2C', '3C', '4C', '5C', '6C', '7C', '8C', '9C', 'TC', 'JC', 'QC', 'KC',
        'AS', '2S', '3S', '4S', '5S', '6S', '7S', '8S', '9S', 'TS', 'JS', 'QS', 'KS',
        'AD', '2D', '3D', '4D', '5D', '6D', '7D', '8D', '9D', 'TD', 'JD', 'QD', 'KD',
        'AH', '2H', '3H', '4H', '5H', '6H', '7H', '8H', '9H', 'TH', 'JH', 'QH', 'KH'
    ]
    win = 0
    amount = 0.001

    constructor(_messageHandler, _Contract, _game) {
        super(_messageHandler, _Contract, _game)
    }




    getActiveGame() {
        this.messageHandler({ type: 'betloader' })
        this.contract.methods
            .session()
            .call({ from: this.account })
            .then(function (result) {
                console.log(result)
                var betAmount = result[1]
                var cards = []
                if (betAmount > 0) {
                    for (let index = 0; index < result[2].length; index++) {
                        const element = result[2][index];
                        cards.push({ card: "cardspng/" + this.allCards[element - 1] + ".png", selected: false })
                    }
                }
                this.messageHandler(
                    {
                        type: 'restoregame',
                        bet: betAmount,
                        cards: cards
                    })
            }.bind(this), function (params) {
                this.messageHandler({ type: 'error', msg: 'User rejected code:' + params.code })
                this.messageHandler({ type: 'betbutton' })
            }.bind(this))
    }

    betHandler(amount) {
        if (this.tokenObj.address == '0x0000000000000000000000000000000000000000') {
            this.betMainHandler(amount)
        } else {
            this.betTokenHandler(amount)
        }
    }

    async betTokenHandler(bet_amount) {
        if (!this.connected) {
            this.messageHandler({ type: 'error', msg: 'Not connected' })
            return
        }
        this.bet_amount = bet_amount;
        this.messageHandler({ type: 'betloader' })


        var betAmt = Web3.utils.toWei("" + this.bet_amount, this.tokenObj.decimals)
        var sendData = {
            from: this.account
        }

        if (this.tokenObj.extraFeeGasLimit > 0) {
            var gasLimit = await this.contract.methods.bet(this.tokenObj.address, betAmt).estimateGas(
                { from: this.account }
            )
            gasLimit = parseInt(gasLimit * this.tokenObj.extraFeeGasLimit)
            var gasPrice = await window.web3.eth.getGasPrice()
            gasPrice = parseInt(gasPrice * this.tokenObj.extraFeeGasPrice)
            sendData.gasLimit = gasLimit
            sendData.gasPrice = gasPrice
        }

        console.log(sendData)

        this.contract.methods
            .bet(this.tokenObj.address, betAmt)
            .send(sendData)
            .then(function (result) {
                this.processResult(result.events.Bet, 'betresult')
            }.bind(this), function (params) {
                this.messageHandler({ type: 'error', msg: 'User rejected code:' + params.code })
                this.messageHandler({ type: 'betbutton' })
            }.bind(this))

    }


    async betMainHandler(bet_amount) {
        if (!this.connected) {
            this.messageHandler({ type: 'error', msg: 'Not connected' })
            return
        }
        this.bet_amount = bet_amount;
        this.messageHandler({ type: 'betloader' })


        var sendData = {
            from: this.account,
            value: Web3.utils.toWei("" + this.bet_amount, 'ether')
        }

        if (this.tokenObj.extraFeeGasLimit > 0) {
            var gasLimit = await this.contract.methods.bet().estimateGas(
                {
                    from: this.account,
                    value: Web3.utils.toWei("" + this.bet_amount, 'ether')
                }
            )
            gasLimit = parseInt(gasLimit * this.tokenObj.extraFeeGasLimit)

            var gasPrice = await window.web3.eth.getGasPrice()
            gasPrice = parseInt(gasPrice * this.tokenObj.extraFeeGasPrice)


            sendData.gasLimit = gasLimit
            sendData.gasPrice = gasPrice
        }


        console.log(sendData)

        this.contract.methods
            .bet()
            .send(sendData)
            .then(function (result) {
                this.processResult(result.events.Bet, 'betresult')
            }.bind(this), function (params) {
                this.messageHandler({ type: 'error', msg: 'User rejected code:' + params.code })
                this.messageHandler({ type: 'betbutton' })
            }.bind(this))

    }

    resolveHandler(selectedIndexes) {
        if (!this.connected) {
            this.messageHandler({ type: 'error', msg: 'Not connected' })
            return
        }
        this.selectedIndexes = selectedIndexes;
        this.messageHandler({ type: 'betloader' })

        this.contract.methods
            .resolve(selectedIndexes)
            .send({ from: this.account, value: Web3.utils.toWei("0", this.tokenObj.decimals) })
            .then(function (result) {
                this.processResult(result.events.Win, 'betresolve')

            }.bind(this), function (params) {
                this.messageHandler({ type: 'error', msg: 'User rejected code:' + params.code })
                this.messageHandler({ type: 'betbutton' })
            }.bind(this))

    }

    processResult(result, type) {
        var win = 0;
        if (result.returnValues.win && result.returnValues.win.length > 0) {
            win = Web3.utils.fromWei(result.returnValues.win, this.tokenObj.decimals);
        }

        var bet = 0;
        if (result.returnValues.bet && result.returnValues.bet.length > 0) {
            bet = Web3.utils.fromWei(result.returnValues.bet, this.tokenObj.decimals);
        }

        var outcome = 0
        if (result.returnValues.outcome) {
            outcome = parseInt(result.returnValues.outcome)
        }

        var cards = []
        for (let index = 0; index < result.returnValues.output.length; index++) {
            const element = result.returnValues.output[index];
            cards.push({ card: "cardspng/" + this.allCards[element - 1] + ".png", selected: false })
        }
        var player = ""
        if (result.returnValues.player) {
            player = result.returnValues.player
        }

        this.messageHandler(
            {
                type: type,
                win: parseFloat(parseFloat(win).toFixed(15)),
                bet: parseFloat(parseFloat(bet).toFixed(15)),
                tx_id: result.transactionHash,
                cards: cards,
                outcome: outcome,
                player: player,
                currency: this.tokenObj.name,
            })
    }



}

