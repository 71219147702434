<template>
  <div class="main">
    <div class="upTxt">
      <div :class="state.upTxt > 0 ? 'active' : ''">
        <img src="/images/hold.png" v-if="state.upTxt == 1" />
        <span v-if="state.upTxt == 2">HELD</span>
      </div>
    </div>
    <div class="flip-card cardContainer">
      <div
        class="cardContainer"
        v-bind:class="state.classes"
        @click="selectCard()"
      >
        <div
          v-bind:class="state.selected ? 'cardbgselected' : 'cardbg'"
          class="flip-card-front cardImg"
        >
          <img v-bind:src="'/'+state.card" class="playcard cardImg" />
        </div>
        <div
          v-bind:class="state.selected ? 'cardbgselected' : 'cardbg'"
          class="flip-card-back cardImg"
        >
          <img v-bind:src="'/'+state.cardBack" class="playcard cardImg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  components: {},
  data() {
    const state = {
      card: "cardspng/B.png",
      cardBack: "cardspng/B.png",
      selected: false,
      firstBet: false,
      upTxt: 0,
      classes: "flip-card-inner",
      flippedBack: true,
      locked: false,
    };

    return {
      state,
    };
  },
  props: ["cardsHandler", "cardindex"],
  mounted() {
    this.cardsHandler(this);
  },
  methods: {
    setCard(card, flip) {
      ////flip-card-inner  flip-card-inner-animate rotateCard
      if (this.state.cardBack != card.card || this.state.flippedBack) {
        if (card.card == "cardspng/B.png") {
          this.state.classes = "flip-card-inner-animate";
          this.state.flippedBack = true;
        } else {
          this.state.classes = "flip-card-inner-animate";
          this.state.flippedBack = true;
          setTimeout(
            function () {
              this.state.cardBack = card.card;
              //this.$forceUpdate()
            }.bind(this),
            300
          );

          setTimeout(
            function () {
              this.state.classes = "flip-card-inner-animate rotateCard";
              this.state.flippedBack = false;
              this.$soundManager.play({ name: "card" });
            }.bind(this),
            parseInt(700 + this.cardindex * 150)
          );
        }
      }

      if (flip) {
        if (this.state.selected) {
          this.state.upTxt = 2;
        }
        this.state.selected = false;
        this.state.locked = false;
      }
    },
    lockSelection() {
      this.state.locked = true;
    },
    setFirstBet(firstBet) {
      this.state.firstBet = firstBet;
    },
    getSelectedState() {
      return this.state.selected;
    },
    setSelected(sel) {
      
      this.state.selected = sel;
      this.state.upTxt = this.state.selected ? 1 : 0;
    },
    selectCard() {
      if (this.state.locked) {
        return;
      }
      if (this.state.firstBet) {
        this.state.selected = !this.state.selected;
        this.state.upTxt = this.state.selected ? 1 : 0;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only #135067 C4C4C4-->
<style scoped>
.active {
  opacity: 100;
}
.main {
  margin: 3px;
}
.upTxt {
  height: 30px;
}
.cardImg {
  min-width: 54px;
  width: 6vw;
}

.cardContainer {
  min-width: 64px;
  width: calc(6vw + 10px);

  min-height: 89px;
  height: calc(9vw + 10px);
}

.playcard {
  mix-blend-mode: darken;
}
.cardbg {
  float: left;
  background: white;
  padding: 5px;
  border-radius: 10px;
}
.cardbgselected {
  background: #ffe81c;
  float: left;
  padding: 5px;
  border-radius: 10px;
}

.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0s;
  transform-style: preserve-3d;
}

.flip-card-inner-animate {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.4s;
  transform-style: preserve-3d;
}

.rotateCard {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}
</style>
